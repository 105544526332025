import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    {
      style: {
        backgroundImage: "url(bg-home-up.png)",
        backgroundOpacity: "0.9",
        width: "100vw"
      },
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        VLayout,
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "", sm10: "", md10: "", "full-width": "" } },
            [
              _c(
                VCard,
                { staticClass: "elevation-3" },
                [
                  _c(
                    VToolbar,
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c(
                        VToolbarTitle,
                        [
                          _c(VIcon, { staticClass: "ma-2" }, [
                            _vm._v("mdi-account-switch")
                          ]),
                          _vm._v("Selecione um novo Apresentante")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c(VCardText, [
                        _vm._v(
                          ' Selecione abaixo o apresentante disponível. Assim que o novo apresentante for confirmado clique em "Trocar Apresentante". Os dados do novo apresentante serão carregados no painel. '
                        )
                      ]),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "ml-4", attrs: { md: "6" } },
                            [
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.filtros,
                                  label: "Filtros",
                                  "item-text": "name",
                                  "item-value": "value"
                                },
                                on: { change: _vm.listarApresentantes },
                                model: {
                                  value: _vm.filterSelected,
                                  callback: function($$v) {
                                    _vm.filterSelected = $$v
                                  },
                                  expression: "filterSelected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VForm,
                        {
                          ref: "form",
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.salvarNovoApresentante.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "2",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c(
                                    VCardText,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-chevron-right")
                                      ]),
                                      _vm._v(" Selecione um apresentante:")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c(VCardText, [
                                    _vm._v(" Nome do Apresentante: ")
                                  ]),
                                  _c(VAutocomplete, {
                                    attrs: {
                                      solo: "",
                                      small: "",
                                      label: "Selecione aqui",
                                      "prepend-icon": "mdi-chevron-down",
                                      items: _vm.apresentantes,
                                      "item-value": "cd_apresentante",
                                      "item-text": "nm_apresentante",
                                      loading: _vm.loading
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              VListItemContent,
                                              [
                                                _c(VListItemTitle, [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "text-gray": !data.item
                                                          .st_ativo
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item
                                                            .nm_apresentante
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c("div", [
                                                    _c("small", [
                                                      _vm._v("Remessa: ")
                                                    ]),
                                                    _c(
                                                      "small",
                                                      {
                                                        class: data.item
                                                          .st_ativo_remessa
                                                          ? "text-green"
                                                          : "text-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            !!data.item
                                                              .st_ativo_remessa
                                                              ? "Ativo"
                                                              : "Inativo"
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c("small", [
                                                      _vm._v(" | Comando: ")
                                                    ]),
                                                    _c(
                                                      "small",
                                                      {
                                                        class: !!data.item
                                                          .st_ativo_comando
                                                          ? "text-green"
                                                          : "text-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            !!data.item
                                                              .st_ativo_comando
                                                              ? "Ativo"
                                                              : "Inativo"
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.apresentanteSelecionado,
                                      callback: function($$v) {
                                        _vm.apresentanteSelecionado = $$v
                                      },
                                      expression: "apresentanteSelecionado"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    lg: "3",
                                    xl: "3"
                                  }
                                },
                                [
                                  _c(
                                    VCardText,
                                    { staticClass: "text-left" },
                                    [_vm._v(" Sigla: ")]
                                  ),
                                  _c(VSelect, {
                                    attrs: {
                                      solo: "",
                                      small: "",
                                      "prepend-icon": "mdi-chevron-down",
                                      label: "Selecione",
                                      items: _vm.apresentantesOrderByCd,
                                      "item-value": "cd_apresentante",
                                      "item-text": "cd_apresentante",
                                      loading: _vm.loading
                                    },
                                    model: {
                                      value: _vm.apresentanteSelecionado,
                                      callback: function($$v) {
                                        _vm.apresentanteSelecionado = $$v
                                      },
                                      expression: "apresentanteSelecionado"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(VCol, { attrs: { lg: "1", xl: "1" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary",
                            "data-cy": "signinSubmitBtn"
                          },
                          on: { click: _vm.salvarNovoApresentante }
                        },
                        [
                          _vm._v(" Trocar Apresentante "),
                          _c(VIcon, { staticClass: "ml-1" }, [
                            _vm._v("chevron_right")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }